:root {
    --soft-blue: #5e91eb;
    --gunmetal: #47535d;
    --cool-grey: #a4a8ab;
    --steel: #8a8e9b;
    --charcoal-grey: #3a444d;
    --pale-grey: #f8f9fa;
    --white: #ffffff;
    --silver: #ced4da;
    --steel-grey: #83878a;
    --pale-grey-two: #e9ecef;
    --dark: #23292f;
    --dark-light: lighten(var(--dark), 5%);
    --tangerine: #ff9600;
    --soft-tangerine: #fdf6e8;
    --red-orange: #ff3d00;
    --pale-olive-green: #aed581;
    --very-light-pink: #fbe9e7;
    --bluegrey: #7384a0;
    --pale-grey-three: #f1f3f5;
    --beige: #f1f8e9;
    --white-two: #e5e5e5;
    --input-background: #f9fafb;
    --lavender: #b8d0f2;
    --lavender-two: #b8d0f2;
    --aquamarine: #b1e0ec;
    --aquamarine-light: #ccebf3;
    --duck-egg-blue: #ecf2fb;
    --navy: #001439;
    --oldlace: #fcf8e3;
    --sienna: #8a6d3b;
    --olive-green: #83b04e;
    --wheat: #fbc77c;
    --robins-egg-blue: #aae2f2;
    --pale-olive: #b6d590;
    --light-salmon: #faa38b;
    --light-blue-grey: #bfb6e9;
}

.backdrop {
    opacity: 0;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 1;
    position: fixed;
    touch-action: none;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-tap-highlight-color: transparent;
}

.backdrop.visible {
    opacity: 1;
    right: 0;
    transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

span.highlighted {
    background-color: #b8d0f2;
}
