.expandable-container {
    position: relative;
    transition: max-height 200ms linear;
}
.expandable-container > .wrapper-container {
    overflow: hidden;
    transition: max-height 200ms linear;
}
.expandable-container > .fadeout {
    background: -webkit-linear-gradient(
        rgba(255, 255, 255, 0) -20%,
        rgba(255, 255, 255, 1) 90%
    );
}
.expandable-container > .fadeout-element {
    position: relative;
    width: 100%;
    background: -webkit-linear-gradient(
        rgba(255, 255, 255, 0) -20%,
        rgba(255, 255, 255, 1) 90%
    );
    bottom: 0;
    height: 18px;
    transition: opacity 200ms linear;
}
.expandable-container > .fadeout-element.fadeout {
    position: absolute;
}
