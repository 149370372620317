.suggestion-panel {
    color: #47535d;
    background-color: white;
    list-style: none;
    box-shadow: 0 2px 5px rgb(0 0 0 / 25%);
    max-height: 240px;
    overflow: auto;
}

.suggestion-panel > li {
    padding: 0px 15px;
    height: 48px;
    font-size: 14px;
    overflow: hidden;
    line-height: 48px;
    transition: 'background 0.15s linear';
    margin: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.suggestion-panel > li:hover {
    background: #eeeeee;
    cursor: pointer;
}

.suggestion-panel > li .highlight {
    color: #23292f;
    background-color: #f8f9fa;
}
