.MuiChip-root.chip-no-delete > .MuiChip-label {
    padding: 2px 6px 4px 6px;
}

.MuiChip-root.chip-delete > .MuiChip-label {
    padding: 0px 5px 0px 6px;
}
.MuiChip-root.chip-delete > .MuiChip-deleteIcon {
    width: 18px;
    height: 18px;
}

.MuiChip-root .MuiChip-labelSmall {
    padding: 2px 6px;
}
